export default function saevents() {
  const searchQuery = document.querySelector("#search-query");
  const searchSubmit = document.querySelector("#search-submit");

  if (searchSubmit) {
    searchSubmit.addEventListener("click", () => {
      sa_event("search_submit", { query: searchQuery.value });
    });
  }
}
