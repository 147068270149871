function navigationOverlayToggle() {
  const menuButton = document.querySelector("#navigation__menu-button");
  const menuList = document.querySelector(".navigation__overlay-nav-list");
  const navigationWrapper = document.querySelector("#navigation");

  if (!menuButton && !navigationWrapper) {
    return;
  }

  menuButton.addEventListener("click", () => {
    navigationWrapper.classList.toggle("navigation--open");
    menuList.classList.toggle("hidden");

    menuButton.innerHTML =
      menuButton.innerHTML === "Schließen" ? "Menü" : "Schließen";
  });
}

export default navigationOverlayToggle;
