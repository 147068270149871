function navigationSlidinOnPageScroll() {
  let previousScrollPosition = window.pageYOffset;
  const navigation = document.querySelector("#navigation");
  const menuButton = document.querySelector("#navigation__menu-button");

  window.addEventListener("scroll", () => {
    const start = 100;
    const currentScrollPosition = window.pageYOffset;
    if (
      currentScrollPosition > start &&
      currentScrollPosition > previousScrollPosition &&
      !navigation.classList.contains("navigation--open")
    ) {
      navigation.classList.add("navigation--slide-up");
    } else {
      navigation.classList.remove("navigation--slide-up");
    }

    previousScrollPosition = currentScrollPosition;
  });

  menuButton.addEventListener("click", () => {
    if (
      navigation.classList.contains("navigation--open") &&
      navigation.classList.contains("navigation--slide-up")
    ) {
      navigation.classList.remove("navigation--slide-up");
    }
  });
}

export default navigationSlidinOnPageScroll;
