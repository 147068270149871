import navigationOverlayToggle from "./navigation-overlay-toggle.js";
import navigationSlidinOnPageScroll from "./navigation-slidin-on-page-scroll.js";
import playLottieAnimationHeader from "./lottie.js";
import saevents from "./saevents.js";

document.addEventListener("DOMContentLoaded", () => {
  /*
  The DOMContentLoaded event fires when the initial HTML document has 
  been completely loaded and parsed, without waiting for stylesheets, 
  images, and subframes to finish loading.
  */
  navigationOverlayToggle();
  navigationSlidinOnPageScroll();
  playLottieAnimationHeader();
  saevents();
});

window.addEventListener("load", () => {
  /*
  The load event is fired when the whole page has loaded, 
  including all dependent resources such as stylesheets and images
  */
});
